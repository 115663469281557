import React from 'react';
import { useNavigate } from 'react-router-dom';
import tvbox from '../images/tvbox.jpg';
import vorschau from '../images/vorschau.jpg';
const ARinfo = () => {
  const navigate = useNavigate();

  const handleProceedToARProject = () => {
    window.location.href = "/ARProjekt/index.html"; // Weiterleitung zur externen Seite
  };

  const handleGoBackToHome = () => {
    navigate("/"); // Zurück zur Startseite
  };

  return (
    <div className="bg-black min-h-screen flex flex-col justify-center items-center p-14">
      <h1 className="text-custom-orange text-4xl font-bold text-center ">Informationen über das AR-Projekt</h1>
      <p className="text-custom-orange text-lg mt-8 p-4 text-center max-w-4xl border-x-2 border-custom-orange rounded-xl bg-custom-gray shadow-sm shadow-white">
        Hier sind einige zusätzliche Informationen über das AR-Projekt bevor es zum eigentlichen Projekt geht. 
        Dies war das IHK-Prüfungsprojekt welches entwickelt wurde für ein spezielles Lager das viele einzigartige Gegenstände mit sich bringt,
        um mit dem AR Image-Tracking Objekte zu scannen damit diese auf dem Display den Inhalt des Objektes anzeigen kann. 
        
      </p>
      <p className="text-custom-orange text-lg mt-8 p-4 text-center max-w-4xl border-x-2 border-custom-orange rounded-xl bg-custom-gray shadow-sm shadow-white">
        Um ein Beispiel zeigen zu können, öffnen Sie zunächst das unten angezeigte Bild auf einem separaten Bildschirm oder drucken Sie es aus.
        Starten Sie nun die eigentliche Anwendung, das AR-Tracking-Tool. Dort gelangen Sie über den Button "Weiter" zur Anwendung.
        Die Anwendung startet, und Sie können mit einer Webcam oder integrierten Laptop-Kamera das Bild, das Sie auf einem anderen Bildschirm geöffnet oder ausgedruckt haben, scannen, 
        und Ihnen wird der originale Inhalt angezeigt.
        Zur eigentlichen Anwendung gehören auch eine eigene Startseite und eine Bestandsliste mit verschiedenen Test-Objekten.   
        Es besteht auch die Möglichkeit, die detaillierte Dokumentation zu lesen. Dafür klicken Sie auf den Button "Doku", und die PDF-Datei "Dokumentation_ARIM.Pdf" (52 Seiten) wird geöffnet.
        Für die Dokumentation und Präsentation erhielte ich am Ende 88 Punkte von den Prüfern. :)
        
        {/* PDF Button */}
      <div className="mt-8">
        <a 
          href="/documents/Dokumentation_ARIM.pdf" 
          target="_blank" 
          rel="noopener noreferrer"
          className="bg-custom-orange text-black py-2 px-4 rounded-lg min-w-[200px] "
        >
          Doku
        </a>
      </div>

      </p>
      <h1 className="text-custom-orange text-2xl p-4 mt-8 font-bold text-center border-x-2 border-custom-orange rounded-xl bg-custom-gray shadow-sm shadow-white">Wichtiger Hinweis: <br></br> Die AR-Anwendung funktioniert nur auf dem Desktop oder auf dem iPad Pro!</h1>
      <p className="text-custom-orange text-lg mt-8 p-4 text-center max-w-4xl border-x-2 border-custom-orange rounded-xl bg-custom-gray shadow-sm shadow-white">
        Öffnen sie dieses Bild auf einem anderen Bildschirm oder Durcken sie es aus, Bild unten.
        
      </p>
        <img src={tvbox}  alt="Box" className=" w-auto h-auto pb-8 pt-8" />


        <h1 className="text-custom-orange text-2xl p-6 font-bold text-center border-x-2 border-custom-orange rounded-xl bg-custom-gray shadow-sm shadow-white"> Nach dem Scannen sollte es im Browser so aussehen siehe Bild unten.</h1>
        <img src={vorschau}  alt="Box" className=" w-[900px] h-auto pb-8 pt-8" />
        


        <h1 className="text-custom-orange text-2xl p-6 font-bold text-center border-x-2 border-custom-orange rounded-xl bg-custom-gray shadow-sm shadow-white "> Klicken sie auf weiter um zur Anwendung zu gelangen.</h1>

      {/* Container für die Buttons, die nebeneinander stehen */}
      <div className="mt-8 flex gap-4">
        

        <button
          className="bg-custom-orange text-black py-2 px-4 rounded-lg"
          onClick={handleGoBackToHome}
        >
          Zurück
        </button>
        <button
          className="bg-custom-orange text-black py-2 px-4 rounded-lg"
          onClick={handleProceedToARProject}
        >
          Weiter
        </button>
      </div>
    </div>
  );
};

export default ARinfo;
