import React, { useState, useRef, useEffect } from 'react';
import '../styles.css';
import '../index.css';
import { FaSun, FaMoon } from "react-icons/fa";

import picLight from '../images/PicLight.png';
import picDark from '../images/PicDark.png';
import betonImage from '../images/Beton.png';
import betonplatteImage from '../images/Betonplatte.png';
import logoImage from '../images/DN_Logo.png';
import bild1Transparent from '../images/bild1Transparent.png';
import xingImage from '../images/xing.png';
import codepenImage from '../images/codepen.png';
import mailImage from '../images/mail.png';
import linkedinImage from '../images/linkedin.png';


const BodyTop = () => {
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const overlayImageRef = useRef(null);
  const frameImageRef = useRef(null);
  const mainImageRef = useRef(null);
  const sectionRef = useRef(null);
  const imageRef = useRef(null);
  const mainImageSrc = isDarkMode ? picDark : picLight;

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };


  useEffect(() => {
    // Set CSS variables based on the dark mode state
    if (isDarkMode) {
      document.documentElement.style.setProperty('--primary-color', '#15171c');
      document.documentElement.style.setProperty('--main-brand-color', '#0e8135');
      document.documentElement.style.setProperty('--secondary-color', '#232933');
      document.documentElement.style.setProperty('--light-secondary-color', '#15171c');
      document.documentElement.style.setProperty('--text-color', 'white');
      document.documentElement.style.setProperty('--secondary-text-color', '#b3b4b6');
    } else {
      document.documentElement.style.setProperty('--primary-color', '#f5f5dc');
      document.documentElement.style.setProperty('--main-brand-color', '#F69765');
      document.documentElement.style.setProperty('--secondary-color', '#EADDAE');
      document.documentElement.style.setProperty('--light-secondary-color', '#f5f5dc');
      document.documentElement.style.setProperty('--text-color', '#000000');
      document.documentElement.style.setProperty('--secondary-text-color', '#000000');
    }
  }, [isDarkMode]);

  useEffect(() => {
    const handleScroll = () => {
      const section = sectionRef.current;
      const image = imageRef.current;

      if (section && image) {
        const sectionRect = section.getBoundingClientRect();
        const imageRect = image.getBoundingClientRect();

        // Check if section is in view
        if (sectionRect.top < window.innerHeight && sectionRect.bottom > 0) {
          section.classList.add('animate-slide-up');
          section.classList.remove('animate-slide-down');
        } else {
          section.classList.remove('animate-slide-up');
          section.classList.add('animate-slide-down');
        }

        // Check if image is in view
        if (imageRect.top < window.innerHeight && imageRect.bottom > 0) {
          setTimeout(() => {
            image.classList.add('animate-slide-up');
            image.classList.remove('animate-slide-down');
          }, 200); // Delay for the image slide-up
        } else {
          image.classList.remove('animate-slide-up');
          image.classList.add('animate-slide-down');
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleDarkMode = () => {
    const overlayImage = overlayImageRef.current;
    const frameImage = frameImageRef.current;
    const mainImage = mainImageRef.current;

    // Schritt 1: Zoom-in-Effekt starten
    overlayImage.style.display = 'block'; // Sichtbarkeit einstellen
    setTimeout(() => {
      overlayImage.style.transform = 'scale(1)';
      overlayImage.style.opacity = '1';
    }, 10);

    setTimeout(() => {
      // Schritt 2: Rotations-Effekt starten
      frameImage.style.animation = 'rotateCounterclockwise 1.5s linear';
      overlayImage.style.animation = 'rotateClockwise 1.5s linear';

      setTimeout(() => {
        // Schritt 3: Dark Mode umschalten und Hauptbild ändern
        setIsDarkMode(prev => !prev);
        if (!isDarkMode) {
          mainImage.src = picDark;
        } else {
          mainImage.src = picLight;
        }
      }, 1600);

      setTimeout(() => {
        // Schritt 4: Fade-out-Effekt starten
        overlayImage.style.opacity = '0';
      }, 1650);

      setTimeout(() => {
        // Schritt 5: Effekte zurücksetzen
        overlayImage.style.transform = 'scale(0)';
        overlayImage.style.animation = '';
        frameImage.style.animation = '';
        overlayImage.style.display = 'none';
      }, 1600);

    }, 600);
  };

  return (
    <header className="py-16 px-6 flex flex-wrap justify-center items-center gap-12 w-full h-full" style={{ backgroundColor: "var(--primary-color)", color: "var(--text-color)" }}>



      <div className="w-full  md:w-[850px]">
        {/* Hamburger Menü */}
        <div className="fixed top-0 left-0 w-full bg-gray-900 bg-opacity-75 z-50">
          <button
            id="darkModeButton"
            onClick={toggleDarkMode}
            className="fixed top-4 right-6 bg-gray-800 text-white p-2 border-2 rounded-full z-50"
            style={{ backgroundColor: "var(--main-brand-color)", color: "var(--text-color)"}}
          > {isDarkMode ? (
            <FaSun className="text-3xl" />
          ) : (
            <FaMoon className="text-3xl" />
          )}           
          </button>
          <nav className="flex items-center px-6 py-4 justify-between">
            <div className="text-3xl font-bold text-white">
            <a href="#">
              <img 
                ref={imageRef}
                src={logoImage}
                alt="Profile"
                className="w-20"
              />
              </a>
            </div>
            <div className="md:hidden flex flex-grow justify-center items-center">
              <button
                onClick={toggleMenu}
                className="text-2xl p-2 focus:outline-none"
              >
                <span className="material-symbols-rounded text-white" style={{ fontSize: '40px' }}>menu</span>
              </button>
            </div>
            <ul className={`list-none flex-grow justify-center md:flex ${isMenuOpen ? 'block' : 'hidden'} md:block flex-col md:flex-row gap-5`}>
              <li>
                <a href="#about_me_section" className="hover:text-gray-400 text-white">Über Mich</a>
              </li>
              <li>
                <a href="#projekt_section" className="hover:text-gray-400 text-white">Meine Projekte</a>
              </li>
              <li>
                <a href="#more_information_section" className="hover:text-gray-400 text-white">Mehr Informationen</a>
              </li>
            </ul>
          </nav>
        </div>
        <div>
          <h1 className="mb-28 mt-32 sm:mt-60 md:mt-60 lg:mt-60 text-shadow font-extrabold text-2xl sm:text-5xl md:text-6xl lg:text-7xl leading-tight">
            Dennis Netz,<br />
            <span style={{ color: "var(--main-brand-color)" }}>Anwendungsentwickler</span>
          </h1>

          <p className="subheading font-semibold mt-4 lg:text-3xl sm:text-2xl md:text-1xl">
          Als Anwendungsentwickler bin ich mit Begeisterung dabei, die digitale Welt zu entdecken.
           Mit Spaß an der Freude und den richtigen Erfolgserlebnissen kann man auch mal länger am PC verweilen.
           Hier ein paar Einblicke in meine kreativen und technischen Abenteuer!
          </p>
        </div>
      </div>

      <div className="relative  sm:h-[350px] sm:w-[350px] md:h-[350px] md:w-[350px] h-[300px] w-[300px] overflow-hidden rounded-full flex justify-center items-center">
        <img
          ref={mainImageRef}
          src={mainImageSrc}
          alt=""
          loading="lazy"
          className=" w-[70%] h-[70%] sm:w-[70%] sm:h-[70%] md:w-[65%] md:h-[65%] object-cover rounded-full"
        />
        <img
          ref={frameImageRef}
          src={betonImage}
          alt="Beton"
          loading="lazy"
          className="absolute  w-[calc(100%)] h-[calc(100%)] sm:w-[calc(100%)] sm:h-[calc(100%)] md:w-[calc(100%)] md:h-[calc(100%)] pointer-events-none rounded-full"
        />
        <img
          ref={overlayImageRef}
          src={betonplatteImage}
          alt="Betonplatte"
          loading="lazy"
          className="absolute w-[75%] h-[75%] sm:w-[75%] sm:h-[75%] md:w-[75%] md:h-[75%] rounded-full transform scale-0 opacity-0 transition-transform duration-400 ease-in-out"
        />
      </div>
      {/* Über Mich Sektion */}
      <section
        id="about_me_section"
        ref={sectionRef}
        className="relative py-20 sm:py-36 overflow-hidden w-full mt-44 mb-44 rounded-xl shadow-lg shadow-black translate-y-full transition-transform duration-700"
        style={{ backgroundColor: "var(--secondary-color)", color: "var(--text-color)" }}
      >
        <div className="container mx-auto relative z-10">
          <div className="mb-4 text-center">
            <h2 className="mb-16 text-shadow font-extrabold text-2xl sm:text-4xl md:text-4xl lg:text-5xl leading-tight">Über Mich</h2>
          </div>
          <div className="flex flex-wrap justify-center items-center gap-24 ">
            <div className="about_me_container max-w-[750px] text-center md:text-left">
              <p className="subheading mt-4 font-semibold sm:text-1xl md:text-xl lg:text-2xl leading-[175%]">
                
                Schon früher habe ich gerne Zeit am Computer verbracht. Die vielen Möglichkeiten der Technologie faszinieren und motivieren mich, ständig dazuzulernen.
                Ob im Team oder auch mal alleine – mit der richtigen Herangehensweise findet man immer einen Weg zum Erfolg.
                Weiter unten gibt’s eine kleine Kostprobe meiner kreativen Projekte.
              </p>
            </div>
          </div>
        </div>
    

        {/* Bild hinter dem Text mit Transparenz und Scroll-Animation */}
        <img
          ref={imageRef}
          src={bild1Transparent}
          alt="Profile"
          className="absolute top-28 inset-0 opacity-70 transform translate-y-full transition-transform duration-700 z-0 w-[44rem] h-auto"
          style={{ maxHeight: '500px', objectFit: 'cover' }}
        />
      </section>
      
    </header>



  );
};

const BodyBottom = () => {
  // Ref für alle Grid-Items
  const gridItemsRef = useRef([]);

  useEffect(() => {
    const handleScroll = () => {
      gridItemsRef.current.forEach((gridItem) => {
        if (gridItem) {
          const gridItemRect = gridItem.getBoundingClientRect();
          if (gridItemRect.top < window.innerHeight && gridItemRect.bottom > 0) {
            gridItem.classList.add('fade-in');
            gridItem.classList.remove('fade-out');
          } else {
            gridItem.classList.remove('fade-in');
            gridItem.classList.add('fade-out');
          }
        }
      });
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <footer id="more_information_section" className="pt-40 " style={{ backgroundColor: "var(--light-secondary-color)" }}>
      <h2 className="text-center font-extrabold text-shadow text-2xl sm:text-4xl md:text-4xl lg:text-5xl mb-20" style={{ color: "var(--text-color)" }}>
        Mehr Informationen
      </h2>
      <div className="grid_container mx-auto grid grid-cols-1 sm:grid-cols-2 sm:grid-rows-2 lg:grid-cols-2 lg:grid-rows-3 xl:grid-cols-3 gap-6 w-full" style={{ maxWidth: '90%' }}>
        {/* Item 1 */}
        <div ref={(el) => (gridItemsRef.current[0] = el)} className="hover:transform hover:-translate-y-3 hover:shadow-3xl ease-in-out opacity-0 transition-opacity duration-200 grid_item rounded-xl shadow-lg shadow-black flex justify-center items-center p-12 gap-12 col-span-1 sm:col-span-2 lg:col-span-2 xl:col-span-2 h-48 sm:h-80 md:h-80 lg:h-88" style={{ backgroundColor: "var(--secondary-color)" }}>
          <a href="https://www.xing.com/profile/Dennis_Netz033140/cv">
            <img src={xingImage} alt="Xing" className="h-auto w-28 lg:h-48 lg:w-48 sm:h-40 sm:w-48" />
          </a>
          <div>
            <h3 className="text-lm font-bold sm:text-2xl" style={{ color: "var(--text-color)" }}>Xing</h3>
            <p className="text-sm sm:text-xl" style={{ color: "var(--text-color)" }}>
              Mein Profil auf Xing, schau doch mal vorbei.
            </p>
          </div>
        </div>
        
        {/* Item 2 */}
        <div  ref={(el) => (gridItemsRef.current[1] = el)} className="hover:transform hover:-translate-y-3 hover:shadow-3xl ease-in-out opacity-0 transition-opacity duration-700 grid_item rounded-xl shadow-lg shadow-black flex flex-col justify-center items-center p-12 gap-4 col-span-1 lg:row-span-2 " style={{ backgroundColor: "var(--secondary-color)" }}>
          <a href="https://codepen.io/DezoNetz">
            <img src={codepenImage} alt="Codepen" className="h-auto w-28 lg:h-48 lg:w-48 sm:h-40 sm:w-48" />
          </a>
          <div>
            <h3 className="text-lm font-bold sm:text-2xl" style={{ color: "var(--text-color)" }}>Codepen</h3>
          </div>
        </div>
        
        {/* Item 3 */}
        <div ref={(el) => (gridItemsRef.current[2] = el)} className=" hover:transform hover:-translate-y-3 hover:shadow-3xl ease-in-out opacity-0 transition-opacity duration-700 grid_item rounded-xl shadow-lg shadow-black flex flex-col justify-center items-center p-12 gap-4 col-span-1 row-span-2" style={{ backgroundColor: "var(--secondary-color)" }}>
          <a href="mailto:admin@dennis-netz.de">
            <img src={mailImage} alt="Mail" className="h-auto w-28 lg:h-48 lg:w-48 sm:h-40 sm:w-48" />
          </a>
          <div>
            <h3 className="text-lm font-bold text-center sm:text-2xl" style={{ color: "var(--text-color)" }}>Kontakt</h3>
            <p className="text-sm sm:text-xl text-center" style={{ color: "var(--text-color)" }}>
              Habe ich deine Interesse geweckt? Dann kontaktiere mich doch einfach per 
              <a href="mailto:admin@dennis-netz.de" style={{ color: "var(--main-brand-color)" }}> E-Mail</a>.
            </p>
          </div>
        </div>
        
        {/* Item 4 */}
        <div ref={(el) => (gridItemsRef.current[3] = el)} className="hover:transform hover:-translate-y-3 hover:shadow-3xl ease-in-out opacity-0 transition-opacity duration-700 grid_item rounded-xl shadow-lg shadow-black flex flex-col justify-center items-center p-12 gap-4  col-span-1 sm:col-span-1 lg:col-span-2 xl:col-span-1 h-48 sm:h-60 md:h-80 lg:h-88" style={{ backgroundColor: "var(--secondary-color)" }}>
          <a href="#">
            <img src={logoImage} alt="" className="h-auto w-28 " />
          </a>
          <div>
            <h3 className="text-2xl" style={{ color: "var(--text-color)" }}></h3>
          </div>
        </div>
        
        {/* Item 5 */}
        <div ref={(el) => (gridItemsRef.current[4] = el)} className="hover:transform hover:-translate-y-3 hover:shadow-3xl ease-in-out opacity-0 transition-opacity duration-700 grid_item rounded-xl shadow-lg shadow-black flex justify-center items-center p-12 gap-12 col-span-1 sm:col-span-2 lg:col-span-2 xl:col-span-2 h-48 sm:h-80 md:h-80 lg:h-88" style={{ backgroundColor: "var(--secondary-color)" }}>
          <a href="https://de.linkedin.com/in/dennis-netz-891064229?trk=people-guest_people_search-card">
            <img src={linkedinImage} alt="LinkedIn" className="h-auto w-40 lg:h-48 lg:w-48 sm:h-40 sm:w-48" />
          </a>
          <div>
            <h3 className="text-lm font-bold sm:text-2xl" style={{ color: "var(--text-color)" }}>LinkedIn</h3>
            <p className="text-sm sm:text-xl" style={{ color: "var(--text-color)" }}>
              Schau doch mal auf meinem LinkedIn-Profil vorbei.
            </p>
          </div>
        </div>
      </div>
      <div className="w-full mt-32 py-6 mb--32 bg-gray-900 bg-opacity-75 text-white text-center">
      <p>All rights reserved © 2024</p>
      </div>
    </footer>
  );
};

export { BodyTop, BodyBottom };
