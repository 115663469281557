import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header, { BodyBottom, BodyTop } from './components/Body';
import ARinfo from './components/ARinfo';
import Projekt from './components/Projekt';


function App() {
  return (
    <Router>
    <div className="App w-full min-h-screen overflow-x-hidden">
      <Routes>
        {/* Füge hier die Route für ARinfo hinzu */}
        <Route path="/ARinfo" element={<ARinfo />} />
        
        {/* Route für die Hauptseite */}
        <Route
          path="/"
          element={
            <>
              <BodyTop />
              <Projekt />
              <BodyBottom />
            </>
          }
        />
      </Routes>
    </div>
  </Router>
  );
}

export default App;
