import React, { useState } from 'react';
import '../styles.css';
import { useNavigate } from 'react-router-dom';
import rauschenGif from '../images/rauschen.gif';
import miniGameImage from '../images/minigame.png';
import restaurantImage from '../images/restaurant2.png';
import arimImage from '../images/Arim.png';
import tvBackground from '../images/TV.png';

const Projekt = () => {
  const [previewImage, setPreviewImage] = useState(rauschenGif);
  const navigate = useNavigate();
  const showPreview = (imageSrc) => {
    setPreviewImage(imageSrc);
  };
  const resetPreview = () => {
    setPreviewImage(rauschenGif);
  };

  return (
    <section id="projekt_section" className="relative min-h-screen flex justify-center items-center ">
    {/* Hintergrundbild */}
    <div
      className="absolute inset-0 z-10 w-full h-full bg-center bg-no-repeat before:content-[''] before:absolute before:inset-x-0 before:top-0 before:h-24 before:bg-gradient-to-t before:from-transparent before:to-gray-900 after:content-[''] after:absolute after:inset-x-0 after:bottom-0 after:h-24 after:bg-gradient-to-b after:from-transparent after:to-gray-900"
      style={{
        backgroundImage:  `url(${tvBackground})`,
        backgroundSize: "cover",  // Stelle sicher, dass das Bild vollständig angezeigt wird
        
      }}
    ></div>
      <div className="absolute top-[-150px] left-0 right-0 h-[150px] bg-gradient-to-t from-gray-900 to-transparent z-0"></div>
      <div className="absolute bottom-[-150px] left-0 right-0 h-[150px] bg-gradient-to-b from-gray-900 to-transparent z-0"></div>
      {/* Vorschau-Bereich (TV-Bild und Vorschau) */}
      <div className="relative z-0 flex justify-center items-center w-full">
        <div className="tv-screen-container">
          <div
            className="tv-screen relative translate-y-[-2rem] translate-x-[ rem] w-[320px] h-[230px] sm:w-[320px] sm:h-[230px] md:w-[420px] md:h-[360px] lg:w-[420px] lg:h-[360px] xl:w-[350px] xl:h-[280px] overflow-visible "
          >
            <img src={previewImage} alt="Vorschau" className="w-full h-full object-cover" />
          </div>
        </div>
      </div>

      {/* Buttons separat */}
      <div className="buttons-container absolute top-12  left-2/4 transform -translate-x-1/2 z-30 flex flex-col gap-6">
        <button
          className="text-Black font-extrabold text-2xl sm:text-3xl md:text-4xl lg:text-4xl transform opacity-80 transition duration-300 ease-in-out hover:text-white hover:opacity-100 hover:brightness-150"
          onMouseOver={() => showPreview(miniGameImage)}
          onMouseLeave={resetPreview}
          onClick={() => window.location.href ="/projekt1/projekt1.html"}
        >
          Mini-Game
        </button>
        <button
          className="text-black font-extrabold text-2xl sm:text-3xl md:text-4xl lg:text-4xl transform opacity-80 transition duration-300 ease-in-out hover:text-white hover:opacity-100 hover:brightness-150"
          onMouseOver={() => showPreview(restaurantImage)}
          onMouseLeave={resetPreview}
          
        >
          Webseiten
        </button>
        <button
          className="text-black font-extrabold text-2xl sm:text-3xl md:text-4xl lg:text-4xl transform opacity-80 transition duration-300 ease-in-out hover:text-white hover:opacity-100 hover:brightness-150"
          onMouseOver={() => showPreview(arimImage)}
          onMouseLeave={resetPreview}
          onClick={() => navigate("/ARinfo")}
          
        >
          AR-Projekt
        </button>
      </div>
    </section>
  );
};

export default Projekt;
